module.exports = {
  flags: {
    DEV_SSR: false,
    FAST_DEV: false,
    PRESERVE_FILE_DOWNLOAD_CACHE: false,
    PARALLEL_SOURCING: false
  },
  siteMetadata: {
    title: 'Vikasku dagbehandlingstilbud',
    siteUrl: 'https://vikasku.dk',
    description: 'Skoledagbehandling',
    image: '/images/favicon.png'

  },
  plugins: [
    `gatsby-plugin-sass`,
    `gatsby-plugin-sitemap`,
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    `gatsby-plugin-scroll-reveal`,
    `gatsby-plugin-transition-link`,
    {
      resolve: 'gatsby-source-storyblok',
      options: {
        accessToken: 'tNYbkRtPUekpkk8my71H2Qtt',
        homeSlug: 'home',
        version: process.env.NODE_ENV === 'production' ? 'published' : 'draft',
        resolveLinks: 'url'
      }
    },
    'gatsby-plugin-react-helmet',
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
  ],
}
